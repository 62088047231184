import { Article } from '@/Article';
import CLink from '@/CLink';
import { CommonHero } from '@/CommonHero';
import { getSubNavLink } from '@/layout/NavLinks';
import { Seo } from '@/layout/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const AboutUs: Page = () => (
    <Fragment>
        <Seo
            title="About Nuttall Smiles - NuttallSmiles"
            description="About Nuttall Smiles dental Care office."
        />
        <CommonHero img>
            <StaticImage
                src="../../../assets/images/about/about.jpg"
                alt="inside nuttall smiles"
                layout="fullWidth"
                loading="eager"
            />
        </CommonHero>
        <Article>
            <p>
                Welcome to Nuttall Smiles! Dr. Nuttall is excited to meet you and take care of your
                smile. We love getting to know our patients, and we invite you to get to know us as
                well. Please feel free to explore the following pages to learn more about our
                skilled team:
            </p>
            <ul>
                <CLink to={getSubNavLink('Our Safety')}>
                    <li>Ensuring You and Our Team Are Safe</li>
                </CLink>
                <CLink to={getSubNavLink('Meet Dr. Kent Nuttall')}>
                    <li>Meet Dr. Kent Nuttall, DMD</li>
                </CLink>
                <CLink to={getSubNavLink('meet our team')}>
                    <li>Meet Our Team</li>
                </CLink>
                <CLink to={getSubNavLink('blog')}>
                    <li>Blog</li>
                </CLink>
            </ul>
            <br />
            <p>
                If you would like more information, or if you are ready to set up your next visit
                with our friendly dentist in Auburn, Washington, please do not hesitate to contact
                our office today. We are eager to see you here soon!
            </p>
        </Article>
    </Fragment>
);

export default AboutUs;
